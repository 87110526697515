html, 
body {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Slab', serif;
}

p {
    font-family: 'Open Sans', sans-serif;
}

button.is-tmtech {
    background-color: rgb(46, 250, 0);
    border-color: rgb(46, 250, 0);

    &:hover {
        background-color: darken(rgb(46, 250, 0), 6%);
        border-color: rgb(46, 250, 0);
    }

    &:active {
        border-color: rgb(45, 250, 0);
    }

    &:focus {
        border-color: lighten(rgb(46, 250, 0), 6%);
        
        &:not(:active) {
            box-shadow: 0 0 0 0.125em rgba(46, 250, 0, .25)
        }
    }
}