.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    .image {
        max-width: 320px;
    }
}

.title, .subtitle, p {
    color: rgb(245, 245, 245);
    text-align: center;
    max-width: 960px;
}

.title {
    font-size: 3.6em;
    font-weight: 700;
    line-height: 1em;
    padding-bottom: 1.6rem;
}

.subtitle {
    font-size: 1.4em;
    font-weight: 700;
    padding-bottom: 2rem;
}

p {
    color: darken(rgb(45, 250, 0), 10%);
    max-width: 700px;
}

button.is-tmtech {
    margin-top: 1rem;
}

.card {
    border-radius: 4px;
    background-color: rgb(24, 26, 27);

    .card-content {
        padding: 1.5rem;
    }

    .form-title {
        font-size: 1.4em;
        font-weight: 700;
        color: rgb(245, 245, 245);
    }
}

@media screen and (min-width: 1024px) {
    .container {
        padding: 0;
    }
    
    .title {
        line-height: 2em;
        padding-bottom: 0;
    }
}